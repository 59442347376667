<template>
  <v-container>
    <div class="document">
      <div class="document-form">
        <v-form ref="form" @submit.prevent="ValidateForm">
          <v-row>
            <v-col cols="12" md="12" lg="12">
              <v-text-field
                  :label="$t('holidayType.name')"
                  outlined
                  v-model="holidayType.name"
                  :hint="`${server_errors.name}`"
                  persistent-hint
                  @keydown.enter="focusNext"
                  dense>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <v-btn class="btn-save-change" @click="ValidateForm" :loading="btnLoading">
                {{ $t("holidayType.edit") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    holidayType: {},
  },
  data() {
    return {
      btnLoading: false,
      server_errors: {
        name: "",
      },
    }
  },
  methods: {

    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll('input'));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },

    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true;
      }
    },
    saveChange() {
      this.$axios.put(`company/edit/category/holiday`, {
        id: this.holidayType.id,
        name: this.holidayType.name
      }).then((res) => {
        if (res.status === 200) {
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300)
        }
        this.$emit("close");
        this.$emit('success');
        this.btnLoading = false;
      }).catch((error) => {
        this.btnLoading = false;
        if (error.response.status === 422) {
          const obj = error.response.data.errors;
          for (let [key, value] of Object.entries(obj)) {
            this.server_errors[key] = value[0];
          }
        }
        this.btnLoading = false;
      })
    }
  },
}
</script>

<style scoped lang="scss">
.document {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  .document-form {
    width: 500px;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
