<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("holidayType.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field
            class="btn btn-color btn-border btn-border-color"
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('Search.search')"
            v-model="searchItem"
            @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">{{ $t('holidayType.create') }}</v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listHolidayType.length > 0">
          <thead>
          <tr>
            <th class="text-left">{{ $t("holidayType.no") }}</th>
            <th class="text-left">{{ $t("holidayType.name") }}</th>
            <th class="text-left">{{ $t("holidayType.created") }}</th>
            <th class="text-left">{{ $t("holidayType.updated") }}</th>
            <th class="text-left"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, idx) in listHolidayType" :key="idx">
            <td>{{ idx + 1 }}</td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ formatDate(item.created_at) }}
            </td>
            <td>
              {{ formatDate(item.updated_at) }}
            </td>
            <td>

            </td>
            <td class="text-end">
              <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined v-bind="attrs" v-on="on">
                    <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title
                        class="btn-edit"
                        @click="onEdit(item.id)"
                    >Edit
                    </v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-title
                        class="btn-delete"
                        @click="onDeleted(item.id)"
                    >Delete
                    </v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else/>
        <Loading v-if="isLoading"/>
        <ModalEdit fullscreen title="Edit">
          <template v-slot="{ close }">
            <EditHolidayType
                :holidayType="holidayType"
                @close="close"
                @success="fetchHolidayType"
            />
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{ close }">
            <DeleteHolidayType
                @close="close"
                :holidayTypeId="holidayTypeId"
                @success="fetchHolidayType"
            />
          </template>
        </ModalDelete>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../../components/Loading";
import defaultTableNoResult from "../../../components/defaultTableNoResult";
import EditHolidayType from "@/views/MainCompany/HolidayType/form/EditHolidayType";
import DeleteHolidayType from "@/views/MainCompany/HolidayType/form/DeleteHolidayType";
import moment from "moment";

export default {
  components: {
    Loading,
    defaultTableNoResult,
    EditHolidayType,
    DeleteHolidayType
  },
  data() {
    return {
      isLoading: true,
      listHolidayType: [],
      holidayType: {},
      holidayTypeId: "",
      searchItem: "",

    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD")
    },
    searchFilterItem() {
      this.fetchHolidayType();
    },
    filterHolidayType(holidayTypeId) {
      return (
          this.listHolidayType.filter((item) => {
            return item.id == holidayTypeId;
          })[0] || {}
      );
    },

    onCreate() {
      this.$router.push({
        name: "holiday-type.create",
      }).catch(() => {
      });
    },

    onEdit(holidayTypeId) {
      this.holidayType = {
        ...this.filterHolidayType(holidayTypeId),
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(holidayTypeId) {
      this.holidayTypeId = holidayTypeId;
      this.$store.commit("modalDelete_State", true);
    },

    fetchHolidayType() {
      this.isLoading = true;
      this.$axios
          .get(`company/list/category/holidays`, {
            params: {
              filter: this.searchItem,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              this.isLoading = false;
              this.listHolidayType = res.data.data;
              console.log(this.listHolidayType)
            }
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
          });
    },
  },
  created() {
    this.fetchHolidayType();
  },
};
</script>

<style scoped lang="scss">
</style>
